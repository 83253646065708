import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { issuer, clientId, redirectUri, scopes } from "./config";
import Home from "./components/Home/Home";
import MarkCourseCompleted from "./components/MarkCourseCompleted/MarkCourseCompleted";
import HomeScreen from "./components/HomeScreen/HomeScreen";

import "./App.css";

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirectUri,
  scopes: scopes,
});

const App = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch className="container">
        <Route path="/" exact={true} component={Home} />
        <SecureRoute
          path="/markCourseCompleted"
          render={(props) => <MarkCourseCompleted />}
        />
        <SecureRoute path="/home" render={(props) => <HomeScreen />} />
        <Route path="/implicit/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
};

export default App;
