import React from "react";
// import BeatLoader from "react-spinners/BeatLoader";
import { Spinner } from "react-bootstrap";
// import { css } from '@emotion/react';

/* custom css for loader */
// const override = css`
//   display: block;
//   margin-top: 10%;
//   text-align: center;
//   border-color: "#004877";
// `;

export default () => {
  return (
    <>
    <div
          className="beat-loader"
          style={{
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
        <span className="beat-loader" style={{
          textAlign: "center",
          marginTop: "15%",
          marginBottom: "3%",
          display: "block",
          fontSize: "1.5rem",
          color: "#004877"
        }}>Please wait for the next document to load</span>
    <Spinner   size='lg' animation="grow"></Spinner>
    <Spinner   size='lg' animation="grow" ></Spinner>
    <Spinner   size='lg' animation="grow"></Spinner>
    </div>
    </>
  );
};
