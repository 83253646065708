import { useOktaAuth } from "@okta/okta-react";
import { useState, useEffect } from "react";
import { homeApi } from "../api";
import { useCookies } from "react-cookie";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Spinner from "../Spinner";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import CountDownTimer from "../CountDownTimer";


const HomeScreen = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [data, setData] = useState();
  const [authToken, setAuthToken] = useState();
  const [cookies, setCookie] = useCookies(["cornerStoneID"]);
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const [minSecs, setMinSec] = useState({ minutes: 0, seconds: 0 });
  const [showButton, setShowButton] = useState(false);

  const getCookieValue = (cname) => {
    let cookielist = document.cookie.split(";");
    let potentialmatches = cookielist.filter((v) => v.includes(cname));
    for (let cookie of potentialmatches) {
      let cookie_value = cookie.split("=")[1].trim();
      if (cookie_value !== "null") return cookie_value;
    }
  };

  let Key = getCookieValue("ilearnKey");
  let employeeID = getCookieValue("employeeID");
  let CSUserID = getCookieValue("CSUserID");


  useEffect(() => {
    try {
      if (!authState.isAuthenticated) {
        setUserInfo(null);
      } else {
        setAuthToken(authState.accessToken.accessToken);
        postCornerStone();
        oktaAuth.getUser().then((info) => {
          setUserInfo(info);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [authState, oktaAuth]);

  const postCornerStone = () => {
    setLoading(true);
    homeApi
      .postCornerStone(
        authState.accessToken.accessToken,
        Key,
        employeeID,
        CSUserID
      )
      .then((response) => {
        if (error !== undefined) {
          setError(undefined);
        }
        setData(response.data);
        // old code iLearn
        // setEnvelopId(response.data.envelopeId);
        setLoading(false);

        if (response.data.returnUrl.length == 0) {
          setRedirect({ message: "showAlert" });
          const minutes = Math.floor((response.data.timer / 1000 / 60) % 60);
          const seconds = Math.floor((response.data.timer / 1000) % 60);
          setMinSec({ minutes: minutes, seconds: seconds });

          setTimeout(() => {
            setShowButton(true);
          }, response.data.timer);
        } else {
          setRedirect({ message: "showRedirect" });
        }

      })
      .catch((e) => {
        console.error(e);
        setError(e);
        setLoading(true);
      });
  };

  //old code iLearn

  // const setEnvelopId = (envelopeId) => {
  //   setCookie("envelopeId", envelopeId, {
  //     path: "/",
  //   });
  // };

 

  return (
    <div className="home">
      {loading ? (
        <div className="col loader mb-3">
          <Spinner />
        </div>
      ) : (
        <div className="col-lg-4 col-md-5 container showAlert">
          {redirect.message === "showAlert" ? (
            <>
              <Alert variant="success">
                <p className="alertMessage">{data.message}</p>
                {showButton ? (
                  <Button
                    type="button"
                    className="close"
                    id="close"
                    onClick={() => window.close()}
                  >
                    Close Window
                  </Button>
                ) : (
                  <CountDownTimer hoursMinSecs={minSecs} />
                )}
              </Alert>
            </>
          ) : (
            <></>
          )}
          {redirect.message === "showRedirect" ? (
            <>{window.location.replace(data.returnUrl)}</>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default HomeScreen;
