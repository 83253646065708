import axios from "axios";
import { configuration, API_URL, iLearn_URL } from "../../config";
import { browserName, browserVersion } from "react-device-detect";


  export async function postCornerStone(authToken, Key, employeeID, CSUserID ) {
    const responseData = await axios({
      url: `${API_URL}cornerstone/CheckCourseStatus`,
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        cornerStoneEmployeeId: CSUserID,
        employeeId: employeeID,
        keyId: Key,
        browserVersion: `${browserName} ${browserVersion}`
      },
    }).then((result) => {
      return result;
    });
    return responseData;
  }

  export async function postMarkCourseCompleted(authToken, Key, employeeID) {
    const responseData = await axios({
      url: `${API_URL}cornerstone/MarkCourseCompleted`,
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        // envelopeId: envelopeId,
        employeeId: employeeID,
        keyId: Key,
        browserVersion: `${browserName} ${browserVersion}`
      },
    }).then((result) => {
      return result;
    });
    return responseData;
  }

  
